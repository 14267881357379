import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Subject, of } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ConstantsService {
    private constants: any;

    private retrieveConstantsInProgress = false;
    private retrieveConstantsSource = new Subject();

    // eslint-disable-next-line @typescript-eslint/member-ordering
    retrieveConstants$ = this.retrieveConstantsSource.asObservable();

    constructor(private http: HttpClient) {}

    getConstants(): Observable<any> {
        if (this.constants) {
            return of(this.constants);
        } else {
            return this.handleMultipleAPIRequest();
        }
    }

    retrieveConstants(): Observable<any> {
        return this.http.get<object>('get_constants').pipe(
            tap((response: object) => {
                this.constants = response;
            })
        );
    }

    //
    // ─── MANAGE MULTIPLE API REQUEST ────────────────────────────────────────────────────────────────────
    //

    handleMultipleAPIRequest(): any {
        if (this.retrieveConstantsInProgress) {
            return new Observable((observer: any) => {
                this.retrieveConstants$.pipe(finalize(() => (this.retrieveConstantsInProgress = false))).subscribe((constants: any) => {
                    observer.next(constants);
                    observer.complete();
                });
            });
        } else {
            this.retrieveConstantsInProgress = true;
            return this.retrieveConstants().pipe(
                tap((constants: any) => {
                    this.retrieveConstantsSource.next(constants);
                }),
                finalize(() => (this.retrieveConstantsInProgress = false))
            );
        }
    }
}
