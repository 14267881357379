import { ConstantElementMap } from '@app/shared/models/constant.model';

export class Constants {
    public static readonly API_DATE_FORMAT = 'yyyy-MM-dd';

    //
    // ─── TABLE ACTIONS ──────────────────────────────────────────────────────────────
    //

    public static readonly USERS_LIST_ACTIONS = [
        { value: 'EDIT', viewValue: 'Modifica' },
        { value: 'DISABLE', viewValue: 'Disattiva' },
        { value: 'DLETE', viewValue: 'Elimina' },
    ];
    public static readonly USERS_LIST_SORTING_TYPES = 'USER_LIST_SORTING_TYPE';
    public static readonly USERS_LIST_SORTING_TYPE_NAME = 'NAME';
    public static readonly USERS_LIST_SORTING_TYPE_EMAIL = 'EMAIL';
    public static readonly USERS_LIST_SORTING_TYPE_REGISTRATION_DATE = 'REGISTRATION_DATE';

    //USER GRID
    public static readonly USER_LIST_DETAIL_SORTING_TYPE = 'USER_LIST_DETAIL_SORTING_TYPE';
    public static readonly USER_LIST_DETAIL_SORTING_TYPE_NAME = 'NAME';
    public static readonly USER_LIST_DETAIL_SORTING_TYPE_EMAIL = 'EMAIL';
    public static readonly USER_LIST_DETAIL_SORTING_TYPE_TRAINING_PLAN_EXPIRATION_DATE = 'TRAINING_PLAN_EXPIRATION_DATE';
    public static readonly USER_LIST_DETAIL_SORTING_TYPE_NUTRITIONAL_PLAN_EXPIRATION_DATE = 'NUTRITIONAL_PLAN_EXPIRATION_DATE';
    public static readonly USER_LIST_DETAIL_SORTING_TYPE_GYM_PLAN_EXPIRATION_DATE = 'GYM_PLAN_EXPIRATION_DATE';

    public static readonly TRAINING_PLAN_ACTIONS = {
        READY_TO_PROCESS: { tag: 'READY_TO_PROCESS', viewValue: 'prendi in carico' },
        DRAFT: { tag: 'DRAFT', viewValue: 'associa video' },
    };
    public static readonly NUTRITIONAL_PLAN_ACTIONS = {
        REFERT_TO_REVIEW: { tag: 'REFERT_TO_REVIEW', viewValue: 'gestisci piano' },
        SENT: { tag: 'SENT', viewValue: 'Invia a nutrizionista' },
        AWAITING_NUTRITIONIST: { tag: 'AWAITING_NUTRITIONIST', viewValue: 'In attesa del nutrizionista' },
    };

    public static readonly TRAINING_ANAMNESIS_QUESTION_SPORT_PAST_LIST = 'ANAMNESIS_QUESTION_SPORT_PAST_LIST_ID';
    public static readonly TRAINING_ANAMNESIS_QUESTION_SPORT_PLAYER_PAST = 'ANAMNESIS_QUESTION_SPORT_PLAYER_PAST_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_AFTERNOON_SNACK = 'ANAMNESIS_QUESTION_AFTERNOON_SNACK_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_ALCOHOL_NUTR = 'ANAMNESIS_QUESTION_ALCOHOL_NUTR_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_ALIMENTARY_REGYME = 'ANAMNESIS_QUESTION_ALIMENTARY_REGYME_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_ALLERGIES_OR_INTOLERANCES = 'ANAMNESIS_QUESTION_ALLERGIES_OR_INTOLERANCES_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_ANTICOAGULANT = 'ANAMNESIS_QUESTION_ANTICOAGULANT_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_ARTICOLAR_PAINS = 'ANAMNESIS_QUESTION_ARTICOLAR_PAINS_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_BREAKFAST = 'ANAMNESIS_QUESTION_BREAKFAST_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_CONTRACEPTIVE = 'ANAMNESIS_QUESTION_CONTRACEPTIVE_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_CONTRACEPTIVE_SINCE_WHEN = 'ANAMNESIS_QUESTION_CONTRACEPTIVE_SINCE_WHEN_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_CONTRACEPTIVE_WHICH = 'ANAMNESIS_QUESTION_CONTRACEPTIVE_WHICH_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_DECLARATION_1_NUTR = 'ANAMNESIS_QUESTION_DECLARATION_1_NUTR_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_DIABETES = 'ANAMNESIS_QUESTION_DIABETES_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_DINNER = 'ANAMNESIS_QUESTION_DINNER_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_DIURESIS = 'ANAMNESIS_QUESTION_DIURESIS_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_EATING_AT_NIGHT = 'ANAMNESIS_QUESTION_EATING_AT_NIGHT_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_EATING_BETWEEN_MEALS = 'ANAMNESIS_QUESTION_EATING_BETWEEN_MEALS_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_FOOD_OR_SNACKS_CANT_AVOID = 'ANAMNESIS_QUESTION_FOOD_OR_SNACKS_CANT_AVOID_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_FRUITS_DISLIKE = 'ANAMNESIS_QUESTION_FRUITS_DISLIKE_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_INTESTIN_FREQUENCY = 'ANAMNESIS_QUESTION_INTESTIN_FREQUENCY_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_INTESTIN = 'ANAMNESIS_QUESTION_INTESTIN_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_LUNCH = 'ANAMNESIS_QUESTION_LUNCH_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_MEAT_FISH_EGGS_DAIRY_DISLIKE = 'ANAMNESIS_QUESTION_MEAT_FISH_EGGS_DAIRY_DISLIKE_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_MENSTRUAL_CYCLE = 'ANAMNESIS_QUESTION_MENSTRUAL_CYCLE_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_MORNING_SNACK = 'ANAMNESIS_QUESTION_MORNING_SNACK_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_PAST_DIETS = 'ANAMNESIS_QUESTION_PAST_DIETS_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_PAST_PATHOLOGIES = 'ANAMNESIS_QUESTION_PAST_PATHOLOGIES_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_PAST_PREGNANCIES = 'ANAMNESIS_QUESTION_PAST_PREGNANCIES_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_PHARMACEUTICALS_NUTR = 'ANAMNESIS_QUESTION_PHARMACEUTICALS_NUTR_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_PHISICAL_ACTIVITY_FREQUENCY = 'ANAMNESIS_QUESTION_PHISICAL_ACTIVITY_FREQUENCY_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_PHISICAL_ACTIVITY_HOURS = 'ANAMNESIS_QUESTION_PHISICAL_ACTIVITY_HOURS_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_PHISICAL_ACTIVITY = 'ANAMNESIS_QUESTION_PHISICAL_ACTIVITY_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_PREGNANT_OR_BREASTFEEDING = 'ANAMNESIS_QUESTION_PREGNANT_OR_BREASTFEEDING_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_PRESENT_PATHOLOGIES = 'ANAMNESIS_QUESTION_PRESENT_PATHOLOGIES_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_SLEEPING_HOURS = 'ANAMNESIS_QUESTION_SLEEPING_HOURS_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_SLEEPING_QUALITY = 'ANAMNESIS_QUESTION_SLEEPING_QUALITY_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_SMOKE_NUTR = 'ANAMNESIS_QUESTION_SMOKE_NUTR_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_SUDDEN_HUNGER = 'ANAMNESIS_QUESTION_SUDDEN_HUNGER_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_VEGETABLES_DISLIKE = 'ANAMNESIS_QUESTION_VEGETABLES_DISLIKE_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_WATER = 'ANAMNESIS_QUESTION_WATER_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_WEIGHT_HISTORY = 'ANAMNESIS_QUESTION_WEIGHT_HISTORY_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_WORKING_HOURS = 'ANAMNESIS_QUESTION_WORKING_HOURS_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_WORK = 'ANAMNESIS_QUESTION_WORK_ID';
    // NEW QUESTIONS
    public static readonly NUTRITION_ANAMNESIS_QUESTION_BIRTH_DATE = 'ANAMNESIS_QUESTION_BIRTH_DATE_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_CELLULITE = 'ANAMNESIS_QUESTION_CELLULITE_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_DECLARATION_1 = 'ANAMNESIS_QUESTION_DECLARATION_1_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_DECLARATION_2 = 'ANAMNESIS_QUESTION_DECLARATION_2_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_FISCAL_CODE = 'ANAMNESIS_QUESTION_FISCAL_CODE_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_HEIGHT = 'ANAMNESIS_QUESTION_HEIGHT_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_LEGS_PROBLEMS = 'ANAMNESIS_QUESTION_LEGS_PROBLEMS_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_MOONY_MOOD = 'ANAMNESIS_QUESTION_MOONY_MOOD_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_OBJECTIVES = 'ANAMNESIS_QUESTION_OBJECTIVES_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_PAST_TRAINING = 'ANAMNESIS_QUESTION_PAST_TRAINING_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_PHONE_NUMBER = 'ANAMNESIS_QUESTION_PHONE_NUMBER_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_RESIDENCE_ADDRESS = 'ANAMNESIS_QUESTION_RESIDENCE_ADDRESS_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_RESIDENCE_CITY = 'ANAMNESIS_QUESTION_RESIDENCE_CITY_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_SELFESTEEM = 'ANAMNESIS_QUESTION_SELFESTEEM_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_SPORT_PAST_LIST = 'ANAMNESIS_QUESTION_SPORT_PAST_LIST_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_SPORT_PAST_PLAY_FREQUENCY = 'ANAMNESIS_QUESTION_SPORT_PAST_PLAY_FREQUENCY_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_SPORT_PLAYER_PAST = 'ANAMNESIS_QUESTION_SPORT_PLAYER_PAST_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_SURGERY = 'ANAMNESIS_QUESTION_SURGERY_ID';
    public static readonly NUTRITION_ANAMNESIS_QUESTION_WEIGHT = 'ANAMNESIS_QUESTION_WEIGHT_ID';

    //
    // ─── RECIPES ────────────────────────────────────────────────────────────────────
    //

    public static readonly DOCUMENT_TYPES = 'DOCUMENT_TYPES';
    public static readonly DOCUMENT_TYPE_FREE = {
        tag: 'FREE',
        label: 'Gratuito',
    };
    public static readonly DOCUMENT_TYPE_PURCHASABLE = {
        tag: 'PURCHASABLE',
        label: 'Acquistabile',
    };

    //
    // ─── RECIPES ────────────────────────────────────────────────────────────────────
    //

    public static readonly RECIPE_TYPES = 'RECIPE_TYPE';
    public static readonly RECIPE_TYPE_FREE = {
        tag: 'FREE',
        label: 'Gratuito',
    };
    public static readonly RECIPE_TYPE_PURCHASABLE = {
        tag: 'PURCHASABLE',
        label: 'Acquistabile',
    };

    //
    // ─── AUTH ───────────────────────────────────────────────────────────────────────
    //

    public static readonly TOKEN_NAME = 'TOKEN';
    public static readonly REFRESH_TOKEN_NAME = 'REFRESH_TOKEN';
    public static readonly USER = 'USER';

    public static readonly USER_ROLES = 'USER_ROLES';

    public static readonly USER_ROLES_TAG = {
        SUPER_ADMIN: 'SUPER_ADMIN',
        ADMIN: 'ADMIN',
        NUTRITIONIST: 'NUTRITIONIST',
        STANDARD_USER: 'STANDARD_USER',
    };

    public static readonly USER_ROLES_MAP = {
        SUPER_ADMIN: 'Super Admin',
        ADMIN: 'Admin',
        NUTRITIONIST: 'Nutritionist',
        STANDARD_USER: 'User',
    };

    public static readonly USER_PROGRESS_PICTURE_TYPES = 'USER_PROGRESS_PICTURE_TYPES';

    public static readonly SORTING_ORDERS = 'SORTING_ORDERS';
    public static readonly SORTING_ORDER_ASC = 'ASC';
    public static readonly SORTING_ORDER_DESC = 'DESC';

    public static readonly TRAINING_PLAN_STATUSES = 'TRAINING_PLAN_STATUS';
    public static readonly TRAINING_PLAN_STATUS_GENERATED = { tag: 'GENERATED', label: 'Acquistato' };
    public static readonly TRAINING_PLAN_STATUS_READY_TO_PROCESS = {
        tag: 'READY_TO_PROCESS',
        label: 'Anamnesi caricata',
    };
    public static readonly TRAINING_PLAN_STATUS_DRAFT = { tag: 'DRAFT', label: 'Presa in carico' };
    public static readonly TRAINING_PLAN_STATUS_READY = { tag: 'READY', label: 'Piano pubblicato' };
    public static readonly TRAINING_PLAN_STATUS_EXPIRED = { tag: 'EXPIRED', label: 'Piano scaduto' };
    public static readonly TRAINING_PLAN_STATUS_MANUALLY_DELETED = { tag: 'MANUALLY_DELETED', label: 'Cancellato' };

    public static readonly NUTRITIONAL_PLAN_STATUS_GENERATED = { tag: 'GENERATED', label: 'Acquistato' };
    public static readonly NUTRITIONAL_PLAN_STATUS_READY_TO_PROCESS = {
        tag: 'READY_TO_PROCESS',
        label: 'Anamnesi caricata',
    };
    public static readonly NUTRITIONAL_PLAN_STATUS_AWAITING_NUTRITIONIST = {
        tag: 'AWAITING_NUTRITIONIST',
        label: 'In attesa del nutrizionista',
    };
    public static readonly NUTRITIONAL_PLAN_STATUS_DRAFT = { tag: 'DRAFT', label: 'Bozza' };
    public static readonly NUTRITIONAL_PLAN_STATUS_REFERT_TO_REVIEW = {
        tag: 'REFERT_TO_REVIEW',
        label: 'Piano creato',
    };
    public static readonly NUTRITIONAL_PLAN_STATUS_READY = { tag: 'READY', label: 'Piano pronto' };
    public static readonly NUTRITIONAL_PLAN_STATUS_COMPLETED = { tag: 'COMPLETED', label: 'Piano Completato' };

    public static readonly GYM_PLAN_STATUS_QUEUED = { tag: 'QUEUED', label: 'Acquistato' };
    public static readonly GYM_PLAN_STATUS_READY = { tag: 'READY', label: 'Piano pronto' };
    public static readonly GYM_PLAN_STATUS_EXPIRED = { tag: 'EXPIRED', label: 'Piano scaduto' };

    public static readonly GYM_PLAN_TYPES = 'GYM_PLAN_TYPES';
    public static readonly GYM_PLAN_TYPE_STANDARD = {
        tag: 'STANDARD',
        label: 'Standard',
    };
    public static readonly GYM_PLAN_TYPE_PREGNANCY = {
        tag: 'PREGNANCY',
        label: 'In Gravidanza',
    };

    public static readonly GYM_PLAN_CATEGORIES = 'GYM_PLAN_CATEGORIES';
    public static readonly GYM_PLAN_CATEGORY_PREGNANCY_ROOKIE = {
        tag: 'PREGNANCY_ROOKIE',
        label: 'In Gravidanza - principiante',
    };
    public static readonly GYM_PLAN_CATEGORY_PREGNANCY_ADVANCED = {
        tag: 'PREGNANCY_ADVANCED',
        label: 'In Gravidanza - avanzato',
    };

    public static readonly NUTRITIONAL_PLAN_CATEGORIES = 'NUTRITIONAL_PLAN_CATEGORIES';
    public static readonly NUTRITIONAL_PLAN_CATEGORY_GENERIC = 'GENERIC';
    public static readonly NUTRITIONAL_PLAN_CATEGORY_TRAINING = 'TRAINING';

    public static readonly ANAMNESIS_STATUSES = 'ANAMNESIS_STATUSES';
    public static readonly ANAMNESIS_STATUS_DRAFT = { tag: 'DRAFT', label: 'Anamnesi in bozza' };
    public static readonly ANAMNESIS_STATUS_SENT = { tag: 'SENT', label: 'Anamnesi Inviata' };
    public static readonly ANAMNESIS_STATUS_APPROVED = { tag: 'APPROVED', label: 'Anamnesi Approvata' };
    public static readonly ANAMNESIS_STATUS_EXPIRED = { tag: 'EXPIRED', label: 'Anamnesi Scaduta' };

    public static readonly ANAMNESIS_TYPES = 'ANAMNESIS_TYPES';
    public static readonly ANAMNESIS_TYPE_TRAINING = { tag: 'TRAINING', label: 'Training' };
    public static readonly ANAMNESIS_TYPE_NUTRITIONAL = { tag: 'NUTRITIONAL', label: 'Nutritional' };

    public static readonly NUTRITIONAL_PLAN_STATUSES = 'NUTRITIONAL_PLAN_STATUS';

    public static readonly TRAINING_PLAN_STATUS_MAP: ConstantElementMap = {
        GENERATED: 'Generato',
        READY_TO_PROCESS: 'Anamnesi caricata',
        DRAFT: 'Presa in carico',
        READY: 'Piano pubblicato',
        EXPIRED: 'Scaduto',
        MANUALLY_DELETED: 'Cancellato',
    };
    public static readonly NUTRITIONAL_PLAN_STATUS_MAP: ConstantElementMap = {
        GENERATED: 'Generato',
        READY_TO_PROCESS: 'Anamnesi caricata',
        AWAITING_NUTRITIONIST: 'Anamnesi inviata',
        DRAFT: 'Presa in carico',
        REFERT_TO_REVIEW: 'Piano creato',
        READY: 'Piano pubblicato',
        COMPLETED: 'Completato',
    };
    public static readonly GYM_PLAN_STATUS_MAP: ConstantElementMap = {
        QUEUED: 'Acquistato',
        READY: 'Pubblicato',
        EXPIRED: 'Scaduto',
    };

    public static readonly GYM_PLAN_STATUSES = 'GYM_PLAN_STATUSES';

    public static readonly VIDEO_TARGETS = 'VIDEO_TARGETS';
    public static readonly VIDEO_TARGET_TRAINING = 'TRAINING';
    public static readonly VIDEO_TARGET_GYM_STANDARD = 'GYM_STANDARD';
    public static readonly VIDEO_TARGET_GYM_PREGNANCY = 'GYM_PREGNANCY';

    public static readonly VIDEO_EFFORT_LEVELS = 'VIDEO_EFFORT_LEVEL';
    public static readonly VIDEO_EFFORT_LEVEL = {
        NONE: 'Nessuna',
        LOW: 'Bassa',
        MODERATE: 'Moderata',
        HIGH: 'Alta',
        VERY_HIGH: 'Molto Alta',
    };

    public static readonly VIDEO_LIST_SORTING_TYPE = 'VIDEO_LIST_SORTING_TYPE';
    public static readonly VIDEO_CATEGORY_LIST_SORTING_TYPE = 'VIDEO_CATEGORY_LIST_SORTING_TYPE';
    public static readonly GYM_TOOL_LIST_SORTING_TYPE = 'GYM_TOOL_LIST_SORTING_TYPE';

    public static readonly VIDEO_BODY_PARTS = 'VIDEO_BODY_PARTS';
    public static readonly VIDEO_BODY_PART_SHOULDERS = {
        tag: 'SHOULDERS',
        description: 'Spalle',
    };
    public static readonly VIDEO_BODY_PART_ABS = {
        tag: 'ABS',
        description: 'ABS',
    };
    public static readonly VIDEO_BODY_PART_BUTTOCKS = {
        tag: 'BUTTOCKS',
        description: 'Glutei',
    };
    public static readonly VIDEO_BODY_PART_TRICEPS = {
        tag: 'TRICEPS',
        description: 'Tricipiti',
    };
    public static readonly VIDEO_BODY_PART_LEGS = {
        tag: 'LEGS',
        description: 'Gambe',
    };
    public static readonly VIDEO_BODY_PART_BACK = {
        tag: 'BACK',
        description: 'Dorso',
    };
    public static readonly VIDEO_BODY_PART_CARDIO_TOTAL_BODY = {
        tag: 'CARDIO_TOTAL_BODY',
        description: 'Cardio total body',
    };
    public static readonly VIDEO_BODY_PART_STRETCHING = {
        tag: 'STRETCHING',
        description: 'Stretching',
    };
    public static readonly VIDEO_BODY_PART_BICEPS = {
        tag: 'BICEPS',
        description: 'Bicipiti',
    };

    public static readonly VIDEO_TYPES = 'VIDEO_TYPE';
    public static readonly VIDEO_TYPE_FREE = { tag: 'FREE', label: 'Gratuito' };
    public static readonly VIDEO_TYPE_PURCHASABLE = { tag: 'PURCHASABLE', label: 'Acquistabile' };

    public static readonly NUTRITIONAL_PLAN_LINE_TYPES = 'NUTRITIONAL_PLAN_LINE_TYPES';
    public static readonly NUTRITIONAL_PLAN_LINE_TYPE_BREAKFAST = { tag: 'BREAKFAST', label: 'Colazione' };
    public static readonly NUTRITIONAL_PLAN_LINE_TYPE_LUNCH = { tag: 'LUNCH', label: 'Pranzo' };
    public static readonly NUTRITIONAL_PLAN_LINE_TYPE_DINNER = { tag: 'DINNER', label: 'Cena' };
    public static readonly NUTRITIONAL_PLAN_LINE_TYPE_SNACK = { tag: 'SNACK', label: 'Spuntino' };

    public static readonly RECIPE_CATEGORIES = 'RECIPE_CATEGORIES';
    public static readonly RECIPE_CATEGORY_BREAKFAST = { tag: 'BREAKFAST', label: 'Colazione' };
    public static readonly RECIPE_CATEGORY_LUNCH = { tag: 'LUNCH', label: 'Pranzo' };
    public static readonly RECIPE_CATEGORY_DINNER = { tag: 'DINNER', label: 'Cena' };
    public static readonly RECIPE_CATEGORY_SNACK = { tag: 'SNACK', label: 'Spuntino' };
    public static readonly RECIPE_CATEGORY_EBOOK = { tag: 'EBOOK', label: 'Ebook' };

    public static readonly DOCUMENT_CATEGORIES = 'DOCUMENT_CATEGORIES';
    public static readonly DOCUMENT_CATEGORY_PDF = { tag: 'PDF', label: 'PDF' };
    public static readonly DOCUMENT_CATEGORY_EBOOK = { tag: 'EBOOK', label: 'Ebook' };

    public static readonly PRODUCT_STATUSES = 'PRODUCT_STATUSES';
    public static readonly PRODUCT_STATUS_DRAFT = 'DRAFT';
    public static readonly PRODUCT_STATUS_AUTO_DRAFT = 'AUTO_DRAFT';
    public static readonly PRODUCT_STATUS_PENDING = 'PENDING';
    public static readonly PRODUCT_STATUS_PRIVATE = 'PRIVATE';
    public static readonly PRODUCT_STATUS_PUBLISH = 'PUBLISH';
    public static readonly PRODUCT_STATUS_TRASH = 'TRASH';

    public static readonly PRODUCT_CATEGORY_TYPES = 'PRODUCT_CATEGORY_TYPES';
    public static readonly PRODUCT_CATEGORY_TYPE_ECOMMERCE = 'ECOMMERCE';
    public static readonly PRODUCT_CATEGORY_TYPE_TRAINING = 'TRAINING';
    public static readonly PRODUCT_CATEGORY_TYPE_NUTRITIONAL = 'NUTRITIONAL';
    public static readonly PRODUCT_CATEGORY_TYPE_GYM_STANDARD = 'GYM_STANDARD';
    public static readonly PRODUCT_CATEGORY_TYPE_GYM_PREGNANCY = 'GYM_PREGNANCY';

    public static readonly STANDARD_USER_COMMUNICATION_TYPES = 'STANDARD_USER_COMMUNICATION_TYPES';
    public static readonly STANDARD_USER_COMMUNICATION_TYPE_NEW_TRAINING_READY = 'NEW_TRAINING_READY';

    public static readonly STANDARD_USER_PLAN_FLAGS = 'STANDARD_USER_PLAN_FLAGS';
    public static readonly STANDARD_USER_PLAN_FLAG_RUNNING = 'RUNNING';
    public static readonly STANDARD_USER_PLAN_FLAG_RENEWAL = 'RENEWAL';
    public static readonly STANDARD_USER_PLAN_FLAG_NEW = 'NEW';

    public static readonly SEXES = 'SEXES';
    public static readonly SEX_MALE = 'MALE';
    public static readonly SEX_FEMALE = 'FEMALE';

    public static readonly DOCUMENT_FILE_TYPES = 'DOCUMENT_FILE_TYPES';
    public static readonly DOCUMENT_FILE_TYPE_COVER_IMAGE = 'COVER_IMAGE';
    public static readonly DOCUMENT_FILE_TYPE_ATTACHMENT = 'ATTACHMENT';

    public static readonly PROMOTION_DISCOUNT_TYPE = 'PROMOTION_DISCOUNT_TYPE';
    public static readonly PROMOTION_DISCOUNT_TYPE_PERCENTAGE = 'PERCENTAGE';
    public static readonly PROMOTION_DISCOUNT_TYPE_FIXED_VALUE = 'FIXED_VALUE';

    //
    // ─── ERRORS ─────────────────────────────────────────────────────────────────────
    //

    public static readonly ERROR_CANT_INSERT_DUPLICATED_URL = 'CANT_INSERT_DUPLICATED_URL';
    public static readonly ERROR_CANT_OVERLAP_PROGRAMS_ON_GYM_PLAN_TEMPLATE = 'CANT_OVERLAP_PROGRAMS_ON_GYM_PLAN_TEMPLATE';

    public static readonly STANDARD_USER_LIST_TYPES = 'STANDARD_USER_LIST_TYPE';
    public static readonly STANDARD_USER_LIST_TYPE_DEFAULT = 'DEFAULT';

    // PLAN NUTRITIONAL
    public static readonly STANDARD_USER_LIST_TYPE_NUTRITIONAL_PLAN_AWAITING_ANAMNESIS = 'NUTRITIONAL_PLAN_AWAITING_ANAMNESIS';
    public static readonly STANDARD_USER_LIST_TYPE_NUTRITIONAL_PLAN_ANAMNESIS_READY = 'NUTRITIONAL_PLAN_ANAMNESIS_READY';
    public static readonly STANDARD_USER_LIST_TYPE_NUTRITIONAL_PLAN_READY = 'NUTRITIONAL_PLAN_READY';

    // PLAN TRAINING
    public static readonly STANDARD_USER_LIST_TYPE_TRAINING_PLAN_PURCHASED = 'TRAINING_PLAN_PURCHASED';
    public static readonly STANDARD_USER_LIST_TYPE_TRAINING_PLAN_READY_TO_PROCESS = 'TRAINING_PLAN_READY_TO_PROCESS';
    public static readonly STANDARD_USER_LIST_TYPE_TRAINING_PLAN_READY = 'TRAINING_PLAN_READY';
    public static readonly STANDARD_USER_LIST_TYPE_TRAINING_PLAN_RENEWALS_TO_HANDLE = 'TRAINING_PLAN_RENEWALS_TO_HANDLE';
    public static readonly STANDARD_USER_LIST_TYPE_TRAINING_PLAN_RENEWALS_READY = 'TRAINING_PLAN_RENEWALS_READY';

    public static readonly STANDARD_USER_LIST_TYPE_GYM_PLAN_READY = 'GYM_PLAN_READY';
    public static readonly STANDARD_USER_LIST_TYPE_GYM_PLAN_RENEWAL = 'GYM_PLAN_RENEWAL';
}
